// import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Resources from "./pages/Resources";
import Join from "./pages/Join";
import "./App.css";
import "./responsive.css";
import Footer from "./components/Footer";
import Teams from "./pages/Teams";
import Party from "./pages/Party";
import Partner from "./pages/Partner";
import Volunteer from "./pages/Volunteer";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/join" element={<Join />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/party" element={<Party />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/volunteer" element={<Volunteer />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
