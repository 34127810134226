import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

function Navbar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-items");

    const handleClick = () => {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    };

    hamburger.addEventListener("click", handleClick);

    const closeNav = () => {
      hamburger.classList.remove("active");
      navMenu.classList.remove("active");
    };

    document
      .querySelectorAll(".link")
      .forEach((n) => n.addEventListener("click", closeNav));

    // Cleanup the event listeners when the component unmounts
    return () => {
      hamburger.removeEventListener("click", handleClick);

      document
        .querySelectorAll(".link")
        .forEach((n) => n.removeEventListener("click", closeNav));
    };
  }, []);

  useEffect(() => {
    // Update active link based on the current pathname
    const currentPath = location.pathname;
    setActiveLink(currentPath);
  }, [location.pathname]);

  return (
    <nav>
      <div className="navbar">
        <Link to="/" className="logo">
          <img src="/img/logo.svg" alt="ETC logo" />
        </Link>
        <div className="nav-items">
          <ul>
            <li>
              <Link to="/" className={`link ${activeLink === "/" && "active"}`}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`link ${activeLink === "/about" && "active"}`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/teams"
                className={`link ${activeLink === "/teams" && "active"}`}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                to="/party"
                className={`link ${activeLink === "/party" && "active"}`}
              >
                Party
              </Link>
            </li>
            <li>
              <Link
                to="/partner"
                className={`link ${activeLink === "/partner" && "active"}`}
              >
                Partner
              </Link>
            </li>
            <li>
              <Link
                to="https://techinedo.substack.com/"
                target="_blank"
                rel="noreferrer"
                className={`link ${
                  activeLink === "https://techinedo.substack.com/" && "active"
                }`}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/resources"
                className={`link ${activeLink === "/resources" && "active"}`}
              >
                Resources
              </Link>
            </li>
            <li>
              <Link
                to="/volunteer"
                className={`link ${activeLink === "/volunteer" && "active"}`}
              >
                Volunteer
              </Link>
            </li>
          </ul>
          <div className="btn">
            <Link
              to="/join"
              className={`btn1 ${activeLink === "/join" && "active"}`}
            >
              Join Us
            </Link>
          </div>
        </div>

        <div className="hamburger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
