import { useEffect, useState } from "react";
import { RingLoader } from "react-spinners";
import "./volunteer.css";
export default function Volunteer() {
  const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
  //   document.body.appendChild(script);

  //   script.addEventListener("load", () => {
  //     // @TS-ignore
  //     if (window.hbspt) {
  //       // @TS-ignore
  //       window.hbspt.forms.create({
  //         portalId: "27244747",
  //         formId: "128399cc-3b10-477f-aa70-c73c90e3a129",
  //         target: "#hubspotForm",
  //       });

  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 1000);
  //     }
  //   });
  // }, []);

  return (
    <div className="join-volunteer ">
      <div className="jv-banner flex-col">
        <h2 className="">
          <span className="yellow-text">Volunteer</span>
        </h2>
        <p>Join the movement to build a greater Edo tech ecosystem</p>
        <div className="yellow-bar"></div>
      </div>

      {/* <div id="hubspotForm" className="form-card"></div> */}
      <div className="form-container">
        <iframe
          className="volunteer-form"
          src="https://share-eu1.hsforms.com/1EoOZzDsQR3-qcMc8kOOhKQg7y6j"
          // height="600"
          // width="700"
          frameborder="0"
          style={{
            border: "1px solid #bfcbda88",
            borderRadius: "4px",
          }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          title="party"
        ></iframe>
      </div>
    </div>
  );
}
